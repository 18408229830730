import type FilterObject from 'o365.modules.FilterObject.ts';
import { DataGridControl } from 'o365.controls.Grid.DataGrid.ts';
import BaseGridExtension from 'o365.controls.Grid.BaseExtension.ts';

declare module 'o365.controls.Grid.DataGrid.ts' {
    interface DataGridControl {
        filter: DataGridFilter; 
    }
}

export default class DataGridFilter extends BaseGridExtension<DataGridControl> {
    static __extension = 'filter';

    filterObject: FilterObject;

    initializeExtension() {
        const gridControl = this._getGridControl();
        this.filterObject = gridControl.dataObject.filterObject;
    }

}
